import React, { useState, useEffect } from 'react';
import { AgChartsReact } from 'ag-charts-react';

const getData = (results) => {
    let dataArray = []
    if (results === null) {
        return []
    }
    for (let i=0; i<results.length; i++) {
        dataArray.push({
            artist: results[i][0],
            percentage: results[i][1]
        })
    }
    return dataArray
}

const returnMostLikely = (results) => {
  let mostLikely = results[0][0]
  let highest = results[0][1]
  for (let i=0; i<results.length; i++) {
      if (results[i][1] > highest) {
          mostLikely = results[i][0]
          highest = results[i][1]
      }
  }
  return mostLikely
}

export default function ResultsPieChart({ results }) {
    const [options, setOptions] = useState({
        data: [],
        title: {
            text: "Prediction Model Results",
        },
        series: [
            {
              type: "pie",
              angleKey: "percentage",
              calloutLabelKey: "artist",
              sectorLabelKey: "percentage",
              sectorLabel: {
                color: "white",
                fontWeight: "bold",
                formatter: ({ value }) => `${(value * 100).toFixed(0)}%`,
              },
            },
        ],
    });

    useEffect(() => {
      setOptions((prevOptions) => ({
          ...prevOptions,
          data: getData(results)
      }));
    }, [results]);


    if (results !== null) {
      return (
        <div style={{ width: '100%', height: '500px', textAlign:'center' }}>
          <h1>{`We think the producer is ${returnMostLikely(results)}!`}</h1>
          <AgChartsReact options={options} />
        </div>
      )
    } else {
      return <h3 style={{textAlign:'center', padding:'20px'}}>Upload a file to see results!</h3>
    }
};