import React, { useState } from 'react';
import ResultsPieChart from './components/ResultsPieChart';
import {
  Container,
  Row,
  Col,
  Form,
  Button,
} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import axios from 'axios';
import { dotSpinner } from 'ldrs';
import { S3Client, PutObjectCommand, GetObjectCommand, DeleteObjectCommand } from '@aws-sdk/client-s3';
import { SQSClient, ReceiveMessageCommand, DeleteMessageCommand } from "@aws-sdk/client-sqs";
let credentials = require('./credentials.json');

dotSpinner.register()

const S3_BUCKET = 'beat-it-uploads-bucket';
const SQS_QUEUE_URL = 'https://sqs.us-east-2.amazonaws.com/593842122539/Beat-It-Queue';
const REGION = 'us-east-2';
const ACCESS_KEY = credentials.aws_access_key_id;
const SECRET_KEY = credentials.aws_secret_access_key;

function App() {
  const [isValidDuration, setIsValidDuration] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const [results, setResults] = useState(null);

  const BeatItS3Client = new S3Client({
    region: REGION,
    credentials: { 
      accessKeyId: ACCESS_KEY, 
      secretAccessKey: SECRET_KEY 
    }
  });

  const BeatItSQSClient = new SQSClient({
    region: REGION,
    credentials: { 
      accessKeyId: ACCESS_KEY, 
      secretAccessKey: SECRET_KEY 
    }
  });

  async function uploadFile(key, fileStream) {
    const uploadParams = {
      Bucket: S3_BUCKET,
      Key: key,
      Body: fileStream,
    };
  
    try {
      const data = await BeatItS3Client.send(new PutObjectCommand(uploadParams));
      console.log('File uploaded successfully', data);
    } catch (err) {
      console.error('Error uploading file:', err);
    }
  }

  async function deleteFileFromS3(key) {
    try {
      // Create the DeleteObjectCommand
      const command = new DeleteObjectCommand({
        Bucket: S3_BUCKET,
        Key: key,
      });
  
      // Send the command to S3
      await BeatItS3Client.send(command);
  
      console.log(`File ${key} deleted successfully from bucket ${S3_BUCKET}`);
    } catch (error) {
      console.error(`Error deleting file ${key} from bucket ${S3_BUCKET}:`, error);
      throw error; // Rethrow the error if needed
    }
  }

  const receiveMessages = async () => {
    let messages = [];
    let moreMessagesAvailable = true;
  
    while (moreMessagesAvailable) {
      try {
        // Receive messages from SQS queue
        const command = new ReceiveMessageCommand({
          QueueUrl: SQS_QUEUE_URL,
          MaxNumberOfMessages: 10, // Max is 10 messages per request
          VisibilityTimeout: 20,   // Time in seconds that the message will be invisible to other consumers
          WaitTimeSeconds: 0        // Long polling wait time (0 to disable)
        });
  
        const response = await BeatItSQSClient.send(command);
  
        if (response.Messages && response.Messages.length > 0) {
          messages = messages.concat(response.Messages);
          
          // Optionally delete messages after processing them
          for (const message of response.Messages) {
            const deleteCommand = new DeleteMessageCommand({
              QueueUrl: SQS_QUEUE_URL,
              ReceiptHandle: message.ReceiptHandle
            });
            await BeatItSQSClient.send(deleteCommand);
          }
        } else {
          moreMessagesAvailable = false;
        }
      } catch (error) {
        console.error("Error receiving messages:", error);
        moreMessagesAvailable = false;
      }
    }
  
    return messages;
  }

  

  const handleFileSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true)
    let isfound = false;

    const params = {
      QueueUrl: SQS_QUEUE_URL,
      MaxNumberOfMessages: 100,
      WaitTimeSeconds: 20
    };
    

    const file = event.target.formFile.files[0];
    const key = file.name;

    await uploadFile(key, file);

      // let data;
      // while (!isfound) {
      //   let messages = await receiveMessages();

      //   console.log(messages)
      //   console.log(typeof messages)

      //   messages.forEach(message => {
      //     if (message.Body.requestPayload.Records[0].s3.object.key == key) {
      //       data = JSON.parse(message.Body.responsePayload);
      //       isfound = true;
      //     }
      //   })
      // }
    

      // let modifiedData = data.slice(1, -1)
      // modifiedData = modifiedData.split(', ')
      // let final_data = []

      // for (let i=0; i<modifiedData.length; i++) {
      //   let kvpair = modifiedData[i].split(': ')
      //   let name = kvpair[0].slice(1, -1)
      //   let value = parseFloat(kvpair[1])
      //   final_data.push([name, value])
      // }

      // setResults(final_data)
      // setIsLoading(false);

      // deleteFileFromS3(key);


    setTimeout(async () => {
      // Handle file submission logic here
      let data;
      while (!isfound) {
        let messages = await receiveMessages();

        messages.forEach(message => {
          if (JSON.parse(message.Body).requestPayload.Records[0].s3.object.key == key) {
            data = JSON.parse(message.Body).responsePayload;
            isfound = true;
          }
        })
      }
    

      let modifiedData = data.slice(1, -1)
      modifiedData = modifiedData.split(', ')
      let final_data = []

      for (let i=0; i<modifiedData.length; i++) {
        let kvpair = modifiedData[i].split(': ')
        let name = kvpair[0].slice(1, -1)
        let value = parseFloat(kvpair[1])
        final_data.push([name, value])
      }

      setResults(final_data)
      setIsLoading(false);

      deleteFileFromS3(key);

    }, 30000); // Example delay of 30 seconds
  }

  const handleFileChange = e => {
    const file = e.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      const media = document.createElement('video'); // Use video element to handle both video and audio files
      media.src = url;

      media.onloadedmetadata = () => {
        const duration = media.duration;

        if (duration >= 30) {
          setIsValidDuration(true);
          setErrorMessage('');
        } else {
          setIsValidDuration(false);
          setErrorMessage('The audio must be at least 30 seconds long.');
        }

        URL.revokeObjectURL(url); // Clean up the URL object
      };
    }
  }


  return (
    <Container>
      <Col>
        <Row>
          <h1 style={{textAlign:'center'}}> Welcome to Beat-It!</h1>
          <h2 style={{textAlign:'center'}}> Identify who your beat or song sounds like!</h2>
          <h3>Select a method to upload or record a file:</h3>
        </Row>
        <Row>
          <Form onSubmit={handleFileSubmit}>
            <Form.Group controlId='formFile' className='mb-3'>
              <Form.Control accept='video/*, audio/*' type='file' onChange={handleFileChange}/>
            </Form.Group>
            {isValidDuration && (
                <Button style={{width: '100%'}} variant='primary' type='submit'>Submit File</Button>
            )}
          </Form>
        </Row>
      {(!isValidDuration) && (
        <Row>
          <p style={{ textAlign: 'center', color: 'red' }}>{errorMessage}</p>
        </Row>
      )}
      </Col>
      {isLoading && <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        }}>
      
        <l-dot-spinner
          size="40"
          speed="0.9" 
          color="black" 
        ></l-dot-spinner>
      </div>
      }
      <ResultsPieChart results={results}/>
    </Container>
  );
}

export default App;
